@import "./app.scss";

html,
body {
  height: 100%;
  font-family: $font-family-inter;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  line-height: $body-line-height;
  color: $body-color;
  background-color: $body-bg;
  overflow-x: hidden; //--GS
}

#root {
  height: 100%;
}

p {
  font-size: 18px;
  font-weight: 400;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $grey-700;
  text-align: "left";
  margin-top: 0;
  margin-bottom: 0;
}

h1 {
  font-size: 1.75rem;
  font-family: $font-family-roboto;
}

h2 {
  font-size: 26px;
  font-family: $font-family-roboto;
}

h3 {
  font-size: 24px;
  font-family: $font-family-roboto;
}

h4 {
  font-size: 18px;
  font-family: $font-family-roboto;
}

h4.sub-header {
  font-size: 14px;
  font-family: $font-family-roboto;
  color: $grey-600;
  font-weight: normal;
}

.custom-section {
  //background-color: #ccc;
  background-color: $grey-400; //MD
}

.addContainer {
  position: relative;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.boxContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 16px 16px 0px 16px;
}

.boxContainer_for_bigger_pages {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 16px 16px 0px 16px;
}

.text-size-medium {
  font-size: inherit;
}

.text-size-small {
  font-size: smaller;
}

@media only screen and (max-width: 1126px) {
  .new_project {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .Export-button-fix {
    width: 100%;
    margin: 0px !important;
    margin-top: 5 !important;
  }

  .Expand-button-fix {
    width: 100%;
    margin: 0px !important;
    margin-top: 5 !important;
  }
}

s .branch-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0px 0px;
  width: 242px;
}

.branch-container ul {
  width: 242px;
}

.branch-container ul div {
  padding: 2px 6px;
}

.nav-popover-branch {
  top: 1px !important;
}

.paper-container {
  padding: 16px;
  margin-bottom: 32px;
  box-shadow: none !important;
  border-width: 1px;
  border-style: solid;
  border-color: $card-border-color;
  background-color: $card-bg;
  border-radius: 12px !important;
}

.paper-container-save-page {
  padding: 16px;
  margin-bottom: 32px;
  box-shadow: none !important;
  border-width: 1px;
  border-style: solid;
  border-color: $card-border-color;
  background-color: $card-bg;
  border-radius: 12px !important;
}

.paper-helpcenter {
  max-width: 1550px;
  width: 100%;
  border-radius: 16px !important;
  display: flex;
  clear: both;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  // height: auto;
  height: 100%;
  // min-height: 563.93px ;
  //max-height: 563.93px;
  max-height: 710px;
  border-width: 0px !important;
  border-style: none !important;
}

.helpcenter-wrapper {
  text-align: center;
  width: 100%;
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  border-radius: 0px !important;
  display: flex;
}

.box-container-helpcenter {
  margin-top: 36px !important;
  width: 100%;
  height: 100%;
}

.box-container-daily-report {
  margin-top: 110px !important;
  width: 100%;
  height: 100%;
}

.page-dashboard {
  display: flex;
  // height: 70%;
  align-items: center;
  justify-content: center;
  margin-top: 30vh;
}

.custom-textbox {
  input {
    text-align: end;
  }
}

.page-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 0px;
  // margin-bottom: 16px;
}

.buyer-save {
  .buyer-shipping-delivery {
    margin-bottom: 15px;
  }

  .buyer-shipping-delivery-mobile {
    .buyer-shipping-delivery-button {
      margin-bottom: 15px;
      padding: 5px;
    }
  }
}

.MuiTable-root {
  color: $grey-700 !important;
}

.MuiPagination-root .MuiPagination-ul {
  padding-top: 3px !important;
}

.search-space {
  padding-bottom: 16px !important;
}

.heade_list_2_button {
  display: flex;
  padding-bottom: 16px !important;
  justify-content: space-between;
}

.MuiTableCell-head {
  border-top: 1px solid rgba(224, 224, 224, 1);
  color: $grey-700 !important;
  font-weight: bold !important;
  font-size: 14px !important;
  padding: 16px 8px !important;
  line-height: normal !important;
  font-family: $font-family-inter !important;
}

.MuiTableCell-body {
  color: $grey-700 !important;
  font-size: 14px !important;
  padding: 8px 8px !important;
  line-height: normal !important;
  font-family: $font-family-inter !important;
}

.MuiTableRow-hover:hover {
  background-color: $grey-100 !important;
}

.MuiButton-root {
  font-weight: 500 !important;
  font-size: 14px !important;
  font-family: $font-family-roboto !important;
  // line-height: 1.5 !important;
  min-width: 100px !important;
}

.MuiButton-root-for-select {
  min-width: 44px !important;
  min-height: 44px !important;
}

.MuiMenu-root .MuiPaper-elevation {
  //top:64px !important;
  border-radius: 0 0 0.25rem 0.25rem !important;
  box-shadow: 0 0.25rem 0.75rem rgba(18, 38, 63, 0.08) !important;
  border-width: 1px;
  border-style: solid;
  border-color: $card-border-color;
}

.MuiList-root {
  //min-width: 150px !important;
  min-width: 150px !important;
  color: $grey-700 !important;
  // background-color: $sidebar-bg  !important;
}

#popoverTeam .MuiPaper-elevation,
#popoverTransactions .MuiPaper-elevation,
#popoverMaster .MuiPaper-elevation,
#popoverSettings .MuiPaper-elevation {
  background-color: $sidebar-bg !important;
  top: 64px !important;
}

.MuiGrid-root .MuiPaper-root,
.MuiModal-root .MuiPaper-root {
  box-shadow: none !important;
  border-width: 1px;
  border-style: solid;
  border-color: $card-border-color;

  // background-color: $card-bg;
}

// back to original

// .MuiInputLabel-root {
//   font-size: 14px !important;
//   line-height: 1 !important;
// }

.MuiOutlinedInput-input {
  // padding: 12px 12px !important;
  border-color: $grey-400 !important;
  font-family: $font-family-inter !important;
  font-size: 14px !important;
  color: $grey-700 !important;
  // line-height: 1.5 !important;
  background-color: #fff !important;
}

.MuiTypography-root {
  font-family: $font-family-inter !important;
  font-size: 14px !important;
  color: $grey-700 !important;
}

.MuiDialog-paper {
  color: $grey-700 !important;
}

// .MuiDialogActions-root .MuiButton-root{
//     color: $indigo !important;
// }

// .MuiDialogActions-root .MuiButton-root:hover {
//   background-color: $grey-300 !important;
// }

.app-bar .MuiToolbar-root .MuiButton-text {
  font-size: 14px !important;
}

// .MuiBackdrop-root{
//     background-color: $grey-100 !important;
//     opacity: 0.5 !important;
// }

.MuiOutlinedInput-root {
  padding: 0px !important;
}

#tabGSTSale .MuiBox-root,
#tabGSTPurchase .MuiBox-root,
#tabHsnSummary .MuiBox-root {
  padding: 24px 0px;
}

.MuiIconButton-root {
  padding-left: 7px !important;
  margin-right: 1px !important;
  padding-right: 6px !important;
}

.MuiAutocomplete-root {
  font-size: 13px !important;
}

#tabBasicSettings .MuiBox-root,
#tabSecuritySettings .MuiBox-root,
#tabChangePassword .MuiBox-root {
  padding: 0px;
}

.popper {
  max-width: "fit-content";
}

form .MuiGrid-container {
  margin-top: 0 px !important;
}

.vertical-tabs .MuiTabs-flexContainerVertical {
  align-items: flex-start;
}

.validation-message {
  font-family: $font-family-inter !important;
  color: $danger !important;
  //color: #d32f2f !important;
}

.select__control {
  height: 45.13px;
}

.select-options {
  .select__menu {
    z-index: 9;

    .select__group {
      .select__group-heading {
        text-transform: initial !important;
      }
    }

    .select__option {
      padding: 8px 24px;
    }
  }
}

// .select__menu-list {
//   z-index: 9;
// }

// .textfield-grid {
//   fieldset {
//     border-radius: 0px;
//     // border-top-left-radius: 4px;
//     // border-bottom-left-radius: 4px;
//   }
// }

.opening-nature-select {
  .select__control {
    border-radius: 0px !important;
    // border-top-right-radius: 0px;
    // border-bottom-right-radius: 0px;
    // border-top-left-radius: 0px;
    // border-bottom-left-radius: 0px;

    .select__indicator {
      padding: 0px;
    }
  }
}

.basic-single-select__control {
  height: 45.13px;
  width: 400px !important;
}

.show {
  display: $display-block;
}

.hide {
  display: $display-none;
}

.gstfilter {
  margin-top: 13px;
}

.grid-report {
  justify-content: flex-end;
  display: inline;
  padding: 10px;
}

.uppergrid {
  justify-content: flex-end;
  display: inline;
  padding: 10px;
}

.title-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-button-responsive {
  display: flex;
  justify-content: space-between;
}

.button-responsive-stock {
  padding-left: 15px !important;
}

.stock-search-box {
  display: flex;
  margin-bottom: 5px;
}

.search-box-stock {
  padding-left: 15px;
}

.export {
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  justify-content: space-between;
  align-items: center;
}

// .css-mhc70k-MuiGrid-root{
//     margin-left: -16px;
// }
.pagination-label {
  font-size: 12px;
}

// .popoverProfile .MuiPaper-elevation {
//   top: 72px !important;
//   box-shadow: 0px 1px 2px 0px rgb(60 64 67 / 30%),
//     0px 2px 6px 2px rgb(60 64 67 / 15%) !important;
// }
.noPaddingLeft {
  padding-left: 0px !important;
}

.ql-toolbar.ql-snow {
  border: 1px solid #ccc !important;
}

.main-profie-container .MuiPaper-elevation {
  border-radius: 8px;
  top: 72px !important;
  box-shadow:
    0px 1px 2px 0px rgb(60 64 67 / 30%),
    0px 2px 6px 2px rgb(60 64 67 / 15%) !important;

  .profile-user-detail-component {
    border-bottom: 1px solid $grey-200;
    min-width: 295px !important;
    max-width: 427px !important;
    background-color: $header-bg;

    .profile-component-grid {
      .firm-container {
        background-color: $warning-light;
        width: 100%;
        border: solid 1px $warning-border;
        display: inline-flex;
        align-items: center;
        //  justify-content: center;
        padding: 8px 8px;
        border-radius: 4px;
      }

      .avatar-upload {
        min-height: 74px;
        // position: relative;
        // max-width: 97px;
        // margin-right: auto;
        // margin-left: auto;
        margin-top: 16px;
        margin-bottom: 16px;
        width: 100% !important;

        .avatar-edit {
          position: absolute;
          right: 19px;
          z-index: 1;
          bottom: 1px;
        }

        .avatar-preview {
          width: 100%;
          //width: 141px;
          // height: 140px;
          position: relative;
          // border-radius: 100%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          text-align: center;

          .avtar {
            display: flex;
            padding-top: 18px;
            padding-left: 4px;
            font-size: 26px !important;
            height: 73px;
            width: 73px;
            color: #ffffff !important;
            border-radius: 100px;
            justify-content: center;
            text-align: center;
          }
        }
      }

      .title-name {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        color: #232323;
        margin-top: 13px !important;
        bottom: 4.95%;
      }

      .title-description-grid {
        padding-top: 10px;

        .title-description {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          text-align: center;
          color: #606060;
        }
      }
    }

    .profile-name-grid {
      text-align: center;
      margin-bottom: 0px;

      .profilename {
        color: #0b0b45;
      }

      .grid-myprofile {
        /* padding-top: 10px; */
        text-align: center;
        margin-top: 8px;
        margin-bottom: 8px;

        .one-myprofile {
          width: 223px;
        }
      }
    }
  }

  @media only screen and (max-width: 484px) {
    .profile-user-detail-component {
      min-width: 284px !important;
    }
  }

  .branch-selection-component {
    text-align: center;
    padding-top: 10px;
    /* padding-right: 16px; */
    padding-bottom: 10px;
    /* padding-left: 17px; */
    border-bottom: 1px solid #e0e0e0;

    .branch-selection-btn {
      width: 223px;
      height: 37px;

      .branch-selection {
        color: #0b0b45 !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        /* for internet explorer */
        overflow: hidden;
        width: 190px;
        text-align: left;
        text-transform: none;
      }
    }
  }

  .firm-component {
    max-height: 155px;
    overflow-y: auto;
    overflow-x: auto;
    border-bottom: 1px solid #e0e0e0;

    .firm-list-data {
      // padding: 4px 8px;
      width: 100% !important;

      .MuiListItem-button {
        padding: 8px 8px;
      }

      .firm-list-icon {
        margin-right: 10px;
      }

      .firm-list {
        padding-left: 10px;
        width: 100%;
        font-weight: 600;
      }
    }

    .add-another-firm-containter {
      width: 100%;
      display: inline-flex;
      align-items: center;
      //justify-content: center;
      padding: 8px 8px;
      border-radius: 4px;
      // //padding-left: 50px;
    }

    .add-another-firm-containter-disable {
      width: 100%;
      display: inline-flex;
      align-items: center;
      padding-top: 8px;
      padding-right: 11px;
      padding-bottom: 5px;
      border-radius: 0px;
      background-color: #e0e0e0;
    }

    // .add-another-firm-containter-disable:hover {
    //   cursor: none;
    // }

    .add-another-firm-containter:hover {
      background-color: rgba(0, 0, 0, 0.04) !important;
      cursor: pointer;
    }
  }

  //scrollbar
  .firm-component::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    background-color: #f5f5f5;
  }

  .firm-component::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  .firm-component::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $grey-600;
  }

  .financial-list-icon {
    height: 40px;
    border-radius: 100%;
    padding: 4px;
    margin-right: 25px;
  }

  .sign-out-grid {
    padding-top: 8px;
    padding-bottom: 0px;
    text-align: center;

    .sign-out-btn-myprofile {
      width: 223px;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.MuiOutlinedInput-root .MuiCircularProgress-indeterminate {
  margin-right: 70px;
}

.MuiDialogActions-root .button-delete {
  //background-color: #d32f2f !important;
  background-color: $danger !important;
  color: $white !important;
}

.padding-closeIcon {
  padding: 5px 6px 0px 0px;
  cursor: pointer;
}

.MuiDialogActions-root .button-delete:hover {
  //background-color: #ac2525 !important;
  background-color: $danger-dark !important;
}

.MuiDialogActions-root .button-primary {
  background-color: $primary !important;
  color: $white !important;
}

.MuiDialogActions-root .button-primary:hover {
  background-color: $primary !important;
}

.responsive-tabs {
  padding: 28px;
  margin-bottom: 48px;
}

.two-button-myprofile {
  display: inline-flex;
}

// .grid-myprofile {
//     margin-right: 16px;
//     margin-bottom: 10px;
// }

.logo {
  // width:100px;
  // height:50px;
  //pointer-events: none;
  //font-family: 'Source Sans Pro', sans-serif;
  cursor: pointer;
  font-family: $font-family-pacifico;
  // border-radius:8px;
  // background-color:$primary;
  // border:solid 1px $primary;
  // padding: 4px 0px;
  text-align: center;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.logo span {
  //color:#ffffff;
  color: $primary;
  font-size: 30px;
  font-variant: italic;
}

.nav-logo {
  // width:80px ;
  // height:40px;
  // padding: 3px 0px;
  margin-right: 24px;
  position: absolute;
  top: 14px;
}

.manuButton {
  text-transform: none !important;
  margin-right: 5px !important;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  font-family: $font-family-inter !important;
}

.app-bar {
  background-color: $sidebar-bg !important;
  color: $sidebar-menu-item-color !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: $font-family-inter !important;
  // border-bottom-width: 1px;
  // border-bottom-style: solid;
  // border-bottom-color: $border-color;
  box-shadow: none !important;
}

.MuiMenuItem-root {
  color: $sidebar-menu-item-color !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: $font-family-inter !important;
}

.manuButton-link {
  text-transform: uppercase !important;
  //color: #1363DF !important;
  color: $link-color !important;
  display: contents !important;
}

.account-manuButton-link {
  text-transform: initial !important;
  color: $link-color !important;
  display: contents !important;
}

.text-link:hover {
  //border-bottom: 1px solid #1363DF;
  border-bottom: 1px solid $link-hover-color;
  cursor: pointer;
}

.blink {
  animation: blink-animation 2s steps(6, start) infinite;
  -webkit-animation: blink-animation 2s steps(6, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.ck.ck-editor {
  width: 99% !important;
}

.dialogprint {
  padding: 0px !important;
}

.css-1t4vnk2-MuiDialogContent-root {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  padding: 2px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.green-color {
  //color:#279568  !important;
  color: $success !important;
  font-weight: bold !important;
}

.red-color {
  //color:#d32f2f  !important;
  color: $danger !important;
  font-weight: bold !important;
}

.blue-color {
  //color: #1363DF !important;
  color: $blue !important;
  font-weight: bold !important;
}

.dashBoardcardMain {
  display: flex;
}

// .dashboard-card .css-46bh2p-MuiCardContent-root:last-child {
//   padding-top: 11px;
//   padding-right: 10px;
//   padding-bottom: 10px;
//   padding-left: 18px;
// }

.dashboard-card {
  // padding-top: 11px;
  // padding-right: 10px;
  // padding-bottom: 10px;
  // padding-left: 18px;
  border-radius: 12px !important;
}

.sale-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding-left: 15px;
}

.lastest-invoice-main {
  display: flex;
  align-items: center;
  padding: 16px !important;
  // flex-direction: row;
  // justify-content: space-between;
}

.view-all {
  text-align: end;
  display: flex;
  justify-content: end;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.ButtonChartCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.MainSaleGrid {
  justify-content: center;
  height: 500px;
}

.main-container {
  margin: 16px 0px;
}

.sale-container-main-1 {
  height: 400px;
  border-radius: 12px !important;
}

.sale-container-main-2 {
  height: 400px;
  border-radius: 12px !important;
}

.dashboard-card-title {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 4px;
}

.dashboard-card-amount {
  font-size: 25px;
  font-weight: bold;
  color: $primary;
  line-height: 1.1;
  height: 22px;
}

.MuiPagination-ul {
  padding-top: 8px !important;
}

.MuiButtonBase-root.MuiPaginationItem-root {
  padding: 4px 6px 0px 6px;
}

.card-amount-h3 {
  display: flex;
  flex-direction: column;
  height: 100%;

  .card-amount-h3-span {
    color: #0b0b45;
    font-size: 25px;
    font-weight: bold;
  }

  .card-amount-h3-span-red {
    color: $danger;
    font-size: 25px;
    font-weight: bold;
  }

  .card-amount-h3-span-green {
    color: $success;
    font-size: 25px;
    font-weight: bold;
  }
}

.nested-menu {
  padding-left: 8px !important;
}

.top-space {
  padding-top: 16px;
}

.divider-space {
  margin-top: 6px !important;
}

.Dashboard-gridDesign {
  margin-top: 10px;
}

.dashboard-card-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: "center";
}

.card-image {
  margin-top: 10px;
}

.dashboard-chart {
  width: 400px;
}

.doghnut-chart {
  max-width: 300px;
  //width: '100%';
}

.dashboard-bar-chart {
  width: 100%;
}

.dashboard-button-grid {
  justify-content: flex-end;
  display: inline;
  padding: 10px;
}

.css-1s2u09g-control {
  min-height: 45.13px !important;
}

.paper-container-dashbord {
  // padding: 16px;
  margin-bottom: 32px;
  box-shadow: none !important;
  border-width: 1px;
  border-style: solid;
  border-color: $card-border-color;
  background-color: $card-bg;
  border-radius: 12px !important;

  tr:last-child {
    td {
      border-bottom: none;
    }
  }
}

.helpline-number {
  text-decoration: auto;
}

.help-icon {
  color: $grey-600 !important;
  margin-right: 0px !important;
}

.financial-year-list-data {
  border: 1px solid !important;
  border-color: #ccc !important;
  border-radius: 8px !important;
  padding: 10px 5px 10px 10px !important;
  margin-bottom: 10px !important;
  // width: 380px !important;
}

.selected {
  border: #0b0b45 2px solid !important;
}

.icon-design-inpopup {
  color: #0b0b45;
  // height: 1.3em !important;
  // width: 1.3em !important;
  margin-top: 4px;
  margin-right: 10px;
}

#bold-font {
  font-weight: bold !important;
}

.head-dialog-box-container {
  display: flex;

  .closeIcon-btn {
    padding-bottom: 3px;
    padding-top: 2px;
    padding-left: 3px;
    padding-right: 3px;

    .closeIcon-css {
      height: 24px;
      cursor: pointer;
      width: 24px;
    }
  }
}

.dialog-box-main {
  width: 100% !important;
  max-height: 445px !important;
  min-height: 445px !important;
}

.css-1s2u09g-control {
  min-height: 45.13px !important;
}

// .paper-container-dashbord {
//   // padding: 16px;
//   margin-bottom: 32px;
//   box-shadow: none !important;
//   border-width: 1px;
//   border-style: solid;
//   border-color: $card-border-color;
//   background-color: $card-bg;
// }

.helpline-number {
  text-decoration: auto;
}

.help-icon {
  color: $grey-600 !important;
  margin-right: 2px !important;
  margin-left: 2px !important;
  height: 52px !important;
  width: 52px !important;
}

.card-body-padding {
  padding: 10px !important;

  .card-body-font {
    font-size: 1rem;
    border-style: none;
    margin-top: 8px;
    white-space: nowrap;
    max-width: 270px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.grey-card-border {
  border-radius: 2%;
  margin-right: 5px;
  margin-bottom: 5px;
  min-width: 20rem;
  height: 100%;
  max-width: 20rem;
}

.setting-cards {
  min-width: 275;
}

.setting-cards:hover {
  box-shadow:
    0px 1px 2px 0px rgba(60, 64, 67, 0.3),
    0px 2px 6px 2px rgba(60, 64, 67, 0.15) !important;
}

// @media only screen and (max-width: 630px) {
//   .grey-card-border {
//     border-radius: 2%;
//     margin-right: 5px;
//     margin-bottom: 5px;
//     min-width: 15rem;
//     height: 100%;
//     max-width: 15rem;
//   }
// }
.backButton {
  background-color: #fff !important;
  color: black !important;

  border: 1px solid #ccc !important;
  margin-left: 8px !important;
}

.editIconButton {
  margin: 0px !important;
}

.text-amount {
  color: #0b0b45;
  font-weight: bold;
}

.selected-firm-card-component {
  background-color: #0b0b45 !important;
  width: 90px;
  border-radius: 14px !important;

  .selected-firm-typography {
    text-align: center;
    color: #ffffff !important;
  }
}

.ql-editor {
  height: 130px !important;
}

.responsive-total-net-amount-grid {
  font-size: 16px !important;
  font-weight: bold !important;
  margin-top: 10px !important;
}

.main-grid-component-for-total-net-amount {
  display: flex;
}

.profile-user-detail-component-at-login {
  border-bottom: 1px solid $grey-200;
  min-width: 327px !important;
  max-width: 427px !important;

  .profile-component-grid-at-login {
    .firm-container {
      width: 100%;
      display: inline-flex;
      align-items: center;
      padding: 8px 8px;
      border-radius: 4px;

      .profilename {
        font-size: 20px !important;
        color: #0b0b45 !important;
        font-weight: 600;
        max-width: 260px;
      }
    }

    .avatar-container {
      //  margin-top: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      margin-left: 10px;
      display: flex;

      // .avatar-preview-at-login {
      .avtar-at-login {
        display: flex;
        padding-top: 0px;
        padding-left: 1px;
        font-size: 23px !important;
        height: 37px;
        width: 39px;
        color: #161515 !important;
        border-radius: 100px;
        /* justify-content: center; */
        text-align: center;
      }

      .text-size-medium {
        font-size: inherit;
        padding-top: 9px;
        padding-left: 10px;
      }
    }
  }
}

.sign-out-grid-at-login {
  display: flex;
  padding-top: 8px;
  padding-bottom: 0px;
  justify-content: space-evenly;
  text-align: center;
}

.pg-viewer-wrapper {
  overflow: hidden !important;
}

.box-financial-year {
  display: flex;
  align-items: center;
  justify-content: end;
  flex: 1;
  min-width: max-content !important;
  width: 100%;
  gap: 10px;
}

.logo_grid {
  display: flex;
  cursor: pointer;
  border: 1px solid #e9e9ef;
  border-radius: 5px;
  padding-left: 8px;
  height: "100%";
}

.logo_image {
  border-radius: 5px;
  max-height: 35px;
  max-width: 80px;
  min-width: 68px;
}

.pdf-viwer-heading-main-container {
  align-items: center;
}

canvas {
  width: 820px;
  height: 1000px;
}

.dialog-action-print-grid {
  display: flex !important;
  text-align: start;
  align-items: center !important;
  justify-content: start !important;
}

// .pdfviwere-box {
//   height: 1000px;
//   padding-top: 6px;
//   padding-right: 5px;
//   padding-bottom: 5px;
//   padding-left: 5px;
//   width: 824px;
//   background-color: #323639;
// }

.saleBox {
  background-color: $grey-100;
}

.SaleBoxDetailGrid {
  border-bottom: 1px dashed $grey-300 !important;
  padding-bottom: 16px;
  margin-bottom: 16px !important;
}

.pdf-canvas {
  display: flex !important;
  justify-content: center !important;
}

.rpv-core__page-layer--single {
  margin: 0 !important;
}

.rpv-core__inner-page {
  height: 1094.18px;
  width: 93% !important;
  left: 25px !important;
  position: absolute;
  top: 0px;
  transform: translateY(0px);
}

.rpv-core__inner-page {
  left: 0px !important;
}

.animate-bounce {
  animation: bounce 3s infinite;

  @keyframes bounce {

    0%,
    100% {
      transform: translateY(-5%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }

    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
}

.signIn-main-slider {
  background-color: #0b0b45;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  max-width: 80%;

  .signIn-form-label {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff0;
    width: 100%;
    margin-top: 16px;
    padding-left: 16px;

    .signIn-form-label-font {
      color: #fff !important;
      width: 50%;
      font-size: 20px !important;
    }
  }

  .signIn-form-discription {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff0;
    padding-left: 16px;
    width: 100%;
    margin-top: 16px;

    .signIn-form-discription-font {
      color: #fff !important;
      width: 40%;
    }
  }
}

.menu-BootstrapTooltip {
  font-size: 25px !important;

  .css-mz4e27-MuiPopper-root-MuiTooltip-popper {
    .MuiTooltip-tooltip {
      font-size: 25px !important;
      margin-left: 2px;
    }
  }
}

.signIn-main-form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;

  .signIn-sub-form {
    max-width: 400px;
    flex-grow: 1;
    padding: 0px 20px;
  }
}

.setting-page-card {
  border-radius: 10px !important;
}

.setting-page-card .css-46bh2p-MuiCardContent-root:last-child {
  padding-top: 25px;
  padding-right: 10px;
  padding-bottom: 25px;
  padding-left: 18px;
}

.setting-page-card-title {
  font-size: 13px !important;
  font-weight: normal;
  padding-top: 4px;
}

.setting-page-card-box {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: "center";
}

.addon-page-card-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: "center";
}

.card-discription-h3 {
  display: flex;
  flex-direction: column;
  // height: 100%;

  .card-discription-h3-span {
    color: #000;
    // font-size: 20px;
    font-weight: bold;
  }
}

.activeStatus {
  background-color: #f1f6fe !important;
}

.setting-page-card {
  .menu-icon-span {
    background-color: #f1f6fe;
    border-radius: 10px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    .menu-icon {
      font-size: 24px;
    }
  }
}

.addon-page-card {
  cursor: pointer;

  .menu-icon-span {
    background-color: #f1f6fe;
    border-radius: 10px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    .menu-icon {
      font-size: 24px;
    }
  }
}

.setting-page-mainCard {
  border-radius: 10px;
}

// .setting-page-mainCard> :hover {
//   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
// }

.setting-page-card :hover {
  background-color: #f1f6fe;
  cursor: pointer;

  // box-shadow: 0px 1px 2px 0px rgba(60, 64, 67, 0.3), 0px 2px 6px 2px rgba(60, 64, 67, 0.15);
  .menu-icon-span {
    background-color: #0b0b45;
    box-shadow: none !important;

    .menu-icon {
      color: #fff;
      background-color: #0b0b45;
      box-shadow: none !important;
    }
  }
}

.addon-page-card-hover {
  background-color: #f1f6fe !important;
  cursor: pointer;

  .menu-icon-span {
    background-color: #0b0b45;
    box-shadow: none !important;
    border-radius: 10px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    .menu-icon {
      color: #fff;
      background-color: #0b0b45;
      box-shadow: none !important;
    }
  }

  // box-shadow: 0px 1px 2px 0px rgba(60, 64, 67, 0.3), 0px 2px 6px 2px rgba(60, 64, 67, 0.15);
}

.report-menu-page-paper {
  //min-width: 320px;
  min-width: 100%;
  border-radius: 10px !important;
  // max-height: 215px;
  // overflow-y: auto;

  .report-menu-page-MenuList {
    padding-top: 0px;
    border-radius: 10px !important;

    .report-menu-ListItemButton {
      // background-color: #f1f6fe !important;
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
      display: flex !important;
      align-items: center;
      padding: 8px 10px !important;

      .menu-icon-span {
        //background-color: #f1f6fe;
        // background-color: #ff0000;
        //border-radius: 10px;
        // height: 36px;
        // width: 36px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        margin: 0px 0px !important;

        .menu-icon {
          color: #0b0b45;
          // background-color: #0b0b45;
          //font-size: 24px;
        }
      }

      .report-menu-ListItemCount {
        margin-left: 4px;
        margin-right: 4px;
        font-weight: bold;
      }
    }

    @media only screen and (max-width: "500px") {
      .fix-box {
        height: auto !important;
      }
    }

    .fix-box {
      height: 160px;
      overflow-y: auto;

      .number-report-ol {
        padding: 0px !important;

        .number-report-ul {
          padding-left: 30px !important;
        }
      }
    }

    .report-nav-ListItemButton :hover {
      background-color: transparent !important;
    }
  }
}

// .report-menu-page-MenuList:hover {
//   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
// }

// .report-menu-ListItemButton:hover {
//   background-color: none;
// }

.displayOnSmallDevices {
  display: none;
}

.displayNoneSmallDevices {
  display: none;
}

.md-noPaddingLeft {
  padding-left: 16px !important;
}

.saleTableContainer {
  overflow: auto !important;
}

.success {
  color: $success !important;
}

.warning {
  color: $warning !important;
}

.danger {
  color: $danger !important;
}

.expired {
  color: #663c00 !important;
}

.noPadding {
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 4px 0px 5px 5px !important;
}

.smallSize {
  font-size: small !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-11-company-title {
  font-size: 11px !important;
  position: relative;
  top: 6em;
  text-align: center;
}

.primary-color {
  color: $primary !important;
}

.blue-link,
.blue-link:hover,
.blue-link:visited,
.blue-link:active {
  color: #1717f3 !important;
}

.line-height-10 {
  line-height: 10px !important;
}

.selected-plan {
  border-width: 2px !important;
  border-color: #0b0b45 !important;
}

.choose-plan-button-selected {
  background-color: $success !important;
}

.subscription-plan-detail {
  border: 1px solid !important;
  border-color: $card-border-color !important;
  border-radius: 12px !important;
}

.button-hover-none:hover {
  background: none !important;
}

.button-hover-none:active {
  background: none !important;
}

.subscription-overview-plan {
  border: 1px solid !important;
  border-color: $card-border-color !important;
  border-radius: 12px !important;
}

.bold-600 {
  font-weight: 600 !important;
}

.subscription-items-show {
  display: block !important;
}

.subscription-items-hidden {
  display: none !important;
}

.border-color-card {
  border-color: $card-border-color !important;
}

.link-blue-color {
  color: #1717f3 !important;
}

.disable-button:disabled,
.disable-button[disabled] {
  font-weight: 600 !important;
  color: black !important;
}

.e-way-bill-header {
  background-color: #f5f5f5 !important;
  border-bottom: 1px solid #e9e9ef !important;
  padding: 12px 16px !important;
}

.double-underline {
  text-decoration: underline;
  text-decoration-style: double;
  text-underline-position: under;
}

.table-header-color {
  background-color: #f5f5f5;
}

.group-container {
  box-sizing: border-box;
  box-shadow: 0 1px 8px #142e6e1a;
  border-radius: 16px;
  color: #414d56;
  border-color: $card-border-color;
  background-color: $white;
  margin-bottom: 16px;
  padding: 16px;
}

.group_container_for_bigger_pages {
  box-sizing: border-box;
  box-shadow: 0 1px 8px #142e6e1a;
  border-radius: 16px;
  color: #414d56;
  border-color: $card-border-color;
  background-color: $white;
  margin-bottom: 16px;

  // padding-top: 0px;
  // padding-right: 16px;
  // padding-bottom: 16px;
  // padding-left: 16px;
  .heading_box {
    padding-top: 12px;
    padding-right: 9px;
    padding-bottom: 13px;
    padding-left: 16px;
    background-color: white;
  }
}

.row-wrapper-mobile-box {
  box-sizing: border-box;
  border-radius: 16px;
  border-width: 1px;
  border-style: solid;
  border-color: $card-border-color;
  margin-bottom: 16px;
  background-color: $grey-100;
}

// .main-container{
//   background-color: #0b0b4530;
// }
.save-update-cancel-btn-conatiner {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 16px;
}

.remove_ref_adj {
  margin: 0;
  padding: 0;
  background-color: transparent;
}

.ref_adj__amount {
  display: flex;
  gap: 2;
  font-weight: 600;
  align-items: center;
}

.tab-all {
  background-color: #000 !important;
  color: $white;
}

.tab-error {
  background-color: #943432 !important;
  color: $white;
}

.tab-warning {
  background-color: #c19603 !important;
  color: $white;
}

.tab-info {
  background-color: #3780d7 !important;
  color: $white;
}

.tab-success {
  background-color: #448944 !important;
  color: $white;
}

@media only screen and (max-width: 768px) {
  .signIn-main-slider {
    display: none !important;
  }

  .signIn-main-form {
    width: 100% !important;
  }
}

@media only screen and (max-width: 766px) {
  .parent-customer-notes-and-terms-and-condition {
    flex-direction: column;
  }
}

@media only screen and (max-width: "768px") {
  .GridMobileView {
    justify-content: center;
  }

  .pdf-viewer-box-padding {
    padding: 10px !important;
  }
}

@media only screen and (max-width: "599px") {
  .ChallanDeliveryAddress {
    justify-content: center;
  }

  .boxContainerMargin {
    margin: 0px !important;
  }

  .purchaseboxContainerMargin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .product-paper-container {
    padding: 0px !important;
  }
}

@media only screen and (max-width: 991px) {
  .addContainer {
    max-width: 800px;
  }

  p {
    font-size: 16px;
    font-family: $font-family-inter;
    font-weight: 400;
  }

  h1 {
    font-size: 26px;
    font-family: $font-family-roboto;
  }

  h2 {
    font-size: 24px;
    font-family: $font-family-roboto;
  }

  h3 {
    font-size: 22px;
    font-family: $font-family-roboto;
  }

  h4 {
    font-size: 22px;
    font-family: $font-family-roboto;
  }
}

@media only screen and (max-width: 600px) {
  .paper-container {
    padding: 0;
    margin-top: 0;
    margin-bottom: 16px;
  }

  .logo_grid {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .boxContainer {
    box-sizing: inherit;
  }

  .tax-mobile-view {
    width: 300px;
  }

  .paper div:first-child {
    box-shadow: none;
    transition: none;
    border: none;
    margin-top: -1px;
    -webkit-transition: none;
  }
}

@media only screen and (max-width: 484px) {
  .basic-single-select__control {
    height: 45.13px;
    width: 230px !important;
  }
}

@media print {
  button {
    display: none !important;
  }

  @page {
    margin: 0;
  }

  dialogContent {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {
  .color-box {
    margin-top: 20px !important;
  }

  .popoverProfil {
    top: 72px !important;
    box-shadow:
      0px 1px 2px 0px rgb(60 64 67 / 30%),
      0px 2px 6px 2px rgb(60 64 67 / 15%) !important;
    min-width: 100% !important;
  }

  .view-all {
    text-align: end;
    display: inline;
    padding-top: 13px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 9px;
  }

  .MuiPagination-root {
    margin-top: 8px !important;
  }

  // .MuiPaper-rounded {
  //   border-radius: 4px !important;
  // }

  .pagination-label {
    margin-top: 8px !important;
    font-size: 12px;
  }

  #tabBasicSettings,
  #tabSecuritySettings,
  #tabChangePassword {
    // max-width: 210px;
    min-width: "100%";
  }

  .responsive-tabs {
    padding: 0px;
  }

  .one-myprofile {
    width: 100%;
  }

  .purchaseTable {
    display: none;
  }

  .PurchaseNo {
    padding-top: 16px;
  }

  .purchaseBoxItems1 {
    flex-direction: row;
  }
}

@media only screen and (min-width: 769px) {
  .purchaseBox {
    display: none;
  }
}

@media screen and (max-width: "900px") {
  .PurchaseNo {
    margin-top: 9px !important;
  }
}

@media only screen and (max-width: 769px) {
  .md-paddingLeft {
    padding-left: 16px !important;
  }
}

@media only screen and (max-width: 599px) {
  .md-paddingLeft {
    padding-left: 0px !important;
  }
}

@media only screen and (max-width: 720px) {
  .buttton-print-popup {
    display: none !important;
  }
}

@media only screen and (min-width: 720px) {
  .buttton-print-download {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {

  // .dashboard-Rd{
  //     width:70% !important;
  //     height: auto !important;
  // }
  .bar-chart-size {
    //height: 250px !important;
    width: 80% !important;
    height: 160px !important;
  }
}

@media only screen and (max-width: 350px) {
  .bar-chart-size {
    //height: 250px !important;
    width: 190px !important;
    height: 190px !important;
  }

  // .sale-container-main-1 {
  //   height: 280px !important;
  // }

  .dashboard-Rd {
    width: 150px !important;
    height: 150px !important;
  }

  .sale-container-main-2 {
    height: 350px;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard-bar-chart {
    width: 100% !important;
  }

  // .sale-container-main-1 {
  //   height: 250px;
  // }

  // .sale-container-main-2 {
  //     height: 300px;
  // }
  // .dashboard-chart {
  //   // max-width: 350px;
  //    position: "top";
  // }
  .dashboard-Rd {
    width: 70% !important;
    height: auto !important;
  }

  .bar-chart-size {
    //height: 250px !important;
    width: 100%;
    height: 200px;
  }
}

@media only screen and (max-width: 768px) {
  .button-responsive {
    width: 100%;
    margin-top: 10px !important;
  }

  .button-responsive-stock {
    width: 100%;
    margin-top: 10px !important;
    padding-left: 0px !important;
  }

  .button-titel-list {
    display: flex !important;
    justify-content: space-between !important;
  }

  .title-button-responsive {
    display: block;
  }

  .uppergrid {
    padding: 0px;
  }

  .stock-search-box {
    display: block !important;
    margin-bottom: 5px !important;
  }

  .search-box-stock {
    padding-left: 0px !important;
  }
}

@media only screen and (max-width: 400px) {
  .dashboard-button-responsive {
    width: 100%;
    margin-top: 10px !important;
  }

  .dashboard-button-grid {
    // justify-content: flex-end;
    // display: inline;
    padding: 0;
  }
}

.create-branch-button {
  padding-right: 62px !important;
  border-top: 1px solid #dadce0 !important;
  border-radius: 0% !important;
}

@media only screen and (max-width: 768px) {
  .csvButton {
    visibility: hidden;
  }

  // .search-box-sale {
  //   margin-top: 10px !important;
  // }
}

@media only screen and (max-width: 768px) {
  .invoice-bottom-fild {
    max-width: 100% !important;
  }

  .lessthan,
  .filter {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .invoice-top-fild {
    display: block !important;
  }
}

@media only screen and (min-width: 1023px) {
  .showcsv {
    visibility: hidden;
  }

  .saleBox {
    display: none !important;
  }

  .e-way-bill-box {
    display: none !important;
  }
}

@media screen and (max-width: 768px) and (min-width: 382px) {
  .filter {
    width: 30%;
  }
}

@media screen and (max-width: 375px) {
  .lessthan {
    width: 30%;
  }
}

@media only screen and (max-width: 786px) {
  .report-type-lable {
    padding-top: 25px !important;
  }

  .report-type-fromcontrol {
    width: 100% !important;
    padding-top: 15px !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 1135px) {
  .financial-year-responcive {
    margin-right: 11px;
  }
}

@media screen and (min-width: 540px) {
  .PageCenter {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 900px) {
  .displayNoneSmallDevices {
    display: block;
  }
}

@media screen and (min-width: 599px) {
  .displayOnSmallDevices {
    display: block;
  }

  .md-noPaddingLeft {
    padding-left: 0px !important;
  }
}

@media screen and (max-width: 1023px) {
  .saleTable {
    display: none;
  }

  .e-way-bill-table {
    display: none !important;
  }

  .paper-container-save-page {
    padding: 0;
    margin-top: 0;
    margin-bottom: 16px;
    border: none !important;
  }

  .purchaseboxContainerMarginForSmall {
    margin: 0px !important;
  }
}

@media screen and (min-width: 1024px) {
  .saleTableContainer {
    width: 900px !important;
  }
}

@media screen and (min-width: 1440px) {
  .saleTableContainer {
    width: 1330px !important;
  }
}

// .css-ypiqx9-MuiDialogContent-root {
//   padding: 0px !important;
// }

// .Select-menu-outer {
//   z-index: 999 !important;
// }

.account-group-save {
  .validation-message {
    font-family: $font-family-inter !important;
    color: $danger !important;
    //color: #d32f2f !important;
  }

  .textfield-grid {
    margin-bottom: 16px;

    .validation-message {
      font-family: $font-family-inter !important;
      color: $danger !important;
      //color: #d32f2f !important;
    }

    .select__control {
      height: 45.13px;
    }

    .select-options {
      .select__menu {
        z-index: 9;
      }
    }
  }

  .description-grid {
    position: relative;

    .textfield-counter {
      position: absolute;
      right: 0;
    }
  }

  .option-grid {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;

    .select-options {
      .select__menu {
        z-index: 9;
      }
    }

    .validation-message {
      font-family: $font-family-inter !important;
      color: $danger !important;
      //color: #d32f2f !important;
    }
  }

  // .bank-detail-grid {
  // border: 1px solid #0b0b45;
  // border-radius: 4px;
  // margin-left: 16px;
  // padding-right: 16px;
  // padding-bottom: 16px;
  .accounts-middle-title-text-grid {
    .accounts-middle-title-text {
      font-size: 18px !important;
      font-weight: bold;
      margin-top: 0px;
    }
  }
}

//Scroll bar css

// /* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0b0b4530;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0b0b45;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0b0b45;
}

.container-card-shiping-address {
  margin: 0;
  border-radius: 8px;
  gap: 2;
}

.firm-middle-title-text-grid {
  .firm-middle-title-text {
    font-size: 18px !important;
    font-weight: bold;
    margin-top: 0px;
  }
}

.gst-confirm-dialog-box {
  .MuiPaper-root {
    padding: 20px !important;
    border-radius: 12px;
  }

  .gst-dialog-title {
    font-size: 30px;
    font-weight: 700;
    align-self: center;
    color: $primary;
  }

  .gst-popup-text {
    font-size: 18px;
    font-weight: bold;
    color: #232323;
    text-align: center;
  }

  .gst-dialog-action {
    display: flex;
    justify-content: center;
    align-items: center;

    .gst-confirm-btn {
      height: 40px;
      padding: 8px 30px;
      // border: 2px solid $primary !important;
    }

    .gst-close-btn {
      height: 40px;
      padding: 8px 30px;
      border: 2px solid $primary !important;
    }
  }
}

.menu-list {
  overflow: hidden;
}

.menu-list :hover {
  overflow-y: auto;
  overflow-x: hidden;
}

.splash-div {
  display: flex;
  height: 100vh;
  width: 50vh;
  align-items: center;
  justify-content: center;
  // height: inherit;

  @keyframes flickerAnimation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-o-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .animate-flicker {
    -webkit-animation: flickerAnimation 2s infinite;
    -moz-animation: flickerAnimation 2s infinite;
    -o-animation: flickerAnimation 2s infinite;
    animation: flickerAnimation 2s infinite;
  }
}

.delete-container {
  height: 37px;
  color: #dd3431 !important;
}

.delete-container:hover {
  color: #fff !important;
  background-color: #dd3431 !important;
}

.invoice-actions-main-container {
  width: 196px;

  .invoice-action-icons-container {
    display: flex;
    justify-content: flex-start;
  }
}

.listitem {
  height: 37px;
}

.narration-textfield-padding textarea {
  padding: 6px;
}

//Balance Sheet

.parent-text {
  color: #000000 !important;
  font-weight: 600 !important;
}

.child-text {
  span {
    color: #5156be !important;
    // border-bottom: 1px solid $link-hover-color;
  }
}

.child-text:hover {
  span {
    text-decoration: underline;
  }
}

.dashboard-date-range-box {
  width: 250px !important;
}

.selected-list {
  height: 37px !important;
  background-color: #f1f6fe !important;
}

.border-top-and-bottom-right {
  position: relative;
  background-color: #f0f0f0;
  /* Set your desired background color */
}

.border-top-and-bottom-right::before,
.border-top-and-bottom-right::after {
  content: "";
  position: absolute;
  width: 35%;
  height: 1px;
  /* Set the desired border thickness */
  background-color: #000;
  /* Set your desired border color */
}

.border-top-and-bottom-right::before {
  top: 0;
  right: 0;
}

.border-top-and-bottom-right::after {
  bottom: 0;
  right: 0;
}

// .date-range-listitem {
//   height: 37px;
// }
.upgrade-dialog {
  .upgrade-main-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 24px;

    .upgrade-header-box {
      display: flex;
      margin-bottom: 10px;

      .upgrade-icon-box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }

      .upgrade-header-text-box {
        display: flex;
        justify-content: center;
        align-items: center;

        .header-text {
          font-size: 16px !important;
          font-family: $font-family-inter !important;
          font-weight: bold;
        }
      }
    }

    .upgrade-description-box {
      margin-bottom: 20px;

      .upgrade-description-text {
        font-size: 14px !important;
        font-family: $font-family-inter !important;
      }
    }

    .upgrade-buttons-box {
      display: flex;
      justify-content: end;
    }
  }
}

.invite-modal {
  .invite-member-main-box {
    padding: 24px;

    .invite-header-box {
      .invite-icon-box {
        display: flex;
        justify-content: end;
      }

      .invite-image-box {
        display: flex;
        justify-content: center;
      }
    }

    // .invite-member {
    //   display: flex;
    //   justify-content: end;
    //   align-items: center;
    // }

    // .invite-center-image {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    // }

    .invite-text-main-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;

      .header-box {
        margin-bottom: 5px;
      }

      .subheader-box {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
      }

      .branch-name-show-box {
        margin-bottom: 5px;
      }
    }

    .invite-role-box {
      display: flex;
      margin-left: 25px;
      justify-content: start;
      align-items: center;

      .invite-role-text {
        font-size: 14px !important;
      }
    }

    .email-form-content {
      padding: 0px;
      margin: 10px 0px;

      .button-main-box {
        margin-top: 15px;
        padding: 0px 96px;
      }
    }

    .show-alert-box {
      // padding: 0px 30px;
    }
  }
}

.dashboard-date-label {
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-top: 8px;

  .typography-first-child {
    font-weight: 700;
    font-size: 12px !important;
  }

  .typography-second-child {
    font-weight: 800;
    padding-top: 4px !important;
    font-size: 14px !important;
  }
}

.max-user-grid {
  display: flex;
  justify-content: end;
  margin: 16px 16px 16px -28px;
}

.suplier-header {
  display: flex;
  justify-content: space-between;
}

.add-firm-button-box {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  align-items: center;
  // margin: 16px 16px 0px;

  .page-name-box {
    width: 50%;
    margin-top: 16px;

    .page-title {
      font-size: 18px;
      font-weight: bold;
      margin-top: 0px;
    }
  }

  .add-box {
    width: 50%;
    display: flex;
    justify-content: end;
    margin-right: 32px;
    margin-top: 16px;
  }
}

.mob-add-firm-button-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.add-branch-button-box {
  display: flex;
  width: 100%;

  .page-name-box {
    width: 50%;
    margin-top: 16px;

    .page-title {
      font-size: 18px;
      font-weight: bold;
      margin-top: 0px;
    }
  }

  .add-box {
    width: 50%;
    display: flex;
    justify-content: end;
    margin-right: 32px;
    margin-top: 16px;
  }
}

.mob-add-branch-button-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.report-subtext {
  padding: 0px 16px !important;
}

.amount-underline {
  text-decoration: underline;
}

.offline-img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;

  .offline-img {
    height: 420px;
    width: auto;
  }
}

.dialog-title-component {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px !important;
  padding-right: 9px !important;
  padding-bottom: 8px !important;
  padding-left: 10px !important;

  .dialog-title {
    display: flex;
    align-items: center;
    gap: 2;
    justify-content: space-between;
  }
}

.main-container {
  display: grid;
  margin-top: 16px;
  place-items: center;
}

.inner-tabs {
  border-radius: 4px;
  place-content: "center";
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 8px;
  padding-left: 8px;
  font-weight: 800;
}

.desc_quill {
  .ql-toolbar.ql-snow {
    padding: 3px !important;
  }

  .ql-container {
    border: #161515;
    height: 70px;
    overflow: auto;
    resize: vertical;
    background-image: linear-gradient(-45deg, #0b0b45 10px, transparent 5px);

    .ql-editor {
      height: auto !important;

      p {
        font-size: 14px;
      }
    }
  }
}

.desc_quill_for_terms {
  .ql-toolbar.ql-snow {
    padding: 3px !important;
  }

  .ql-container {
    border: #161515;
    height: 120px;
    overflow: auto;
    resize: vertical;
    background-image: linear-gradient(-45deg, #0b0b45 10px, transparent 5px);

    .ql-editor {
      height: auto !important;

      p {
        font-size: 14px;
      }
    }
  }
}

.ql-container {
  .ql-editor {
    p {
      font-size: 14px;
    }
  }
}

.email-dialog-content {
  padding-top: 15px !important;
  padding-right: 14px !important;
  padding-bottom: 0px !important;
  padding-left: 11px !important;
}

.add-transporter {
  padding-left: 16px;
  padding-right: 16px;
  color: #5256be !important;
  text-decoration-color: #5256be !important;
}

.add-transporter:hover {
  text-decoration: underline;
}

.remove-transporter {
  padding-left: 16px;
  padding-right: 16px;
  color: #dd3431 !important;
  text-decoration-color: #dd3431 !important;
}

.remove-transporter:hover {
  text-decoration: underline;
}

.add-reference-adjustment {
  padding-left: 16px;
  padding-right: 16px;
  color: #5256be !important;
  text-decoration-color: #5256be !important;
}

.add-reference-adjustment:hover {
  text-decoration: underline;
}

.remove-reference-adjustment {
  padding-left: 16px;
  padding-right: 16px;
  color: #dd3431 !important;
  text-decoration-color: #dd3431 !important;
}

.remove-reference-adjustment:hover {
  text-decoration: underline;
}

.invoice-prefix {
  border-right: 1px solid #bdbdbd;
  padding-left: 13px;
  padding-top: 4px;
  padding-right: 10px;
  color: #bdbdbd;
  height: 25px;
  border-top-left-radius: 5px;
}

.inline-edit {
  background-color: transparent;
  border: none !important;
  padding: 8px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: bold;
}

.inline-edit:hover {}

.helper-buttons {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
}

.import-error-message-box {
  // background-color: red;
  color: red !important;
}

.drawerWidth {
  width: 80vw !important;
}

.multi-upload-button {
  display: flex;
  height: 36px;
  align-items: center;
  // justify-content: center;
  font-size: 12px !important;
  margin-top: 0px;
  margin-right: 12px;
  margin-bottom: 0px;
  margin-left: 5px;
  width: 150px !important;
  gap: 2px;

  .left-icon {
    text-align: center;
    margin-left: 2px;
    padding-top: 6px;
    margin-right: 5px;
  }
}

.multi-upload-right-icon {
  text-align: center;
  padding-top: 7px;
  /* margin-left: 0px; */
  padding-left: 3px;
  cursor: pointer !important;
}

.fullscreen-dialog-pos {
  .MuiPaper-root {
    .MuiToolbar-root {
      min-height: 38px !important;
      background-color: #606060 !important;

      .header-text {
        color: #fff !important;
        margin: 0;
      }
    }
  }
}

.account-transfer-under-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: "center";
}

.pdf-viewr-simple {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 0px !important;
}

.branch_list_collapsabel_main_box {
  background-color: #f1f6fe;
  border-radius: 16px;
  padding-top: 16px !important;
  padding-right: 16px !important;
  padding-bottom: 5px !important;
  padding-left: 16px !important;
}

.title_branch_main_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}

.title_branch_main_box>.from-to-address-button-box {
  display: flex;
}

.title_branch_main_box>.MuiBox-root {
  gap: 20px;
}



@media only screen and (max-width: 425px) {
  .PurchaseNo {
    margin-top: 0px !important;
  }

  .heade_list_2_button {
    width: 100%;
  }

  .purchaseBoxItems1 {
    flex-direction: column;
    gap: 5px !important;
  }

  .branch-selection {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 120px;
  }

  .addButton {
    display: inline;
  }

  .group_container_for_bigger_pages {
    box-shadow: none;
    margin-bottom: 0px;
    padding: 16px;

    .heading_box {

      padding-left: 0px;
    }
  }

  .boxContainer_for_bigger_pages {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0px !important;
  }

  .title_branch_main_box {
    display: flow-root;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
  }
}